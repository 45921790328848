import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { cva, type VariantProps } from 'class-variance-authority';

import { CheckboxIcon } from '../Checkbox';
import { Icon } from '../Icon';
import { cn } from '../utils';

export type DropdownMenuRootProps = RadixDropdownMenu.DropdownMenuProps;
const Root = RadixDropdownMenu.Root;

export type DropdownMenuTriggerProps = RadixDropdownMenu.DropdownMenuTriggerProps;
const Trigger = RadixDropdownMenu.Trigger;

export type DropdownMenuContentProps = Omit<RadixDropdownMenu.DropdownMenuContentProps, 'asChild'> &
  VariantProps<typeof dropdownClasses> & {
    alignWithIcons?: boolean;
  };

function Content(props: DropdownMenuContentProps) {
  const { className, children, collisionPadding = 8, variant, alignWithIcons, ...rest } = props;
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.Content
        sideOffset={2}
        collisionPadding={collisionPadding}
        {...rest}
        className={cn(
          'flex flex-col rounded py-2 shadow-dropdown',
          alignWithIcons ? 'align-icons' : undefined,
          dropdownClasses({ variant }),
          className
        )}
      >
        {children}
      </RadixDropdownMenu.Content>
    </RadixDropdownMenu.Portal>
  );
}

export type DropdownMenuItemProps = Omit<RadixDropdownMenu.DropdownMenuItemProps, 'asChild'> &
  VariantProps<typeof itemStyle> & {
    iconBefore?: string;
  };
function Item(props: DropdownMenuItemProps) {
  const { className, iconBefore, children, variant, disabled, ...rest } = props;

  return (
    <RadixDropdownMenu.Item
      className={cn(
        itemStyle({ disabled, variant }),
        !iconBefore && variant !== 'unstyled' ? '[.align-icons_&]:pl-7' : '',
        className
      )}
      disabled={disabled}
      {...rest}
    >
      {iconBefore && <Icon className="text-base leading-6" icon={iconBefore} />}
      {children}
    </RadixDropdownMenu.Item>
  );
}

export type DropdownMenuSubProps = RadixDropdownMenu.DropdownMenuSubProps;
const Sub = RadixDropdownMenu.Sub;

export type DropdownMenuSubTriggerProps = RadixDropdownMenu.DropdownMenuSubTriggerProps &
  VariantProps<typeof itemStyle>;

function SubTrigger(props: DropdownMenuSubTriggerProps) {
  const { className, children, variant } = props;
  return (
    <RadixDropdownMenu.SubTrigger className={cn('flex justify-between', itemStyle({ variant }), className)}>
      {children}
      <Icon className="ml-6 inline text-2xl text-white" icon="ic:outline-keyboard-arrow-right" />
    </RadixDropdownMenu.SubTrigger>
  );
}

export type DropdownMenuSubContentProps = Omit<RadixDropdownMenu.DropdownMenuSubContentProps, 'asChild'> &
  VariantProps<typeof dropdownClasses> & {
    alignWithIcons?: boolean;
  };

function SubContent(props: DropdownMenuSubContentProps) {
  const { className, children, collisionPadding = 8, variant, alignWithIcons, ...rest } = props;
  return (
    <RadixDropdownMenu.Portal>
      <RadixDropdownMenu.SubContent
        sideOffset={2}
        alignOffset={-4}
        collisionPadding={collisionPadding}
        {...rest}
        className={cn(
          'z-50 flex flex-col rounded py-2 shadow-dropdown',
          alignWithIcons ? 'align-icons' : undefined,
          dropdownClasses({ variant }),
          className
        )}
      >
        {children}
      </RadixDropdownMenu.SubContent>
    </RadixDropdownMenu.Portal>
  );
}

export type DropdownCheckboxItemProps = RadixDropdownMenu.DropdownMenuCheckboxItemProps &
  VariantProps<typeof itemStyle>;

function CheckboxItem(props: DropdownCheckboxItemProps) {
  const { className, checked, disabled, children, ...rest } = props;
  return (
    <RadixDropdownMenu.CheckboxItem
      className={cn(itemStyle({ disabled }), className)}
      disabled={disabled}
      checked={checked}
      {...rest}
    >
      <CheckboxIcon checked={checked} /> {children}
    </RadixDropdownMenu.CheckboxItem>
  );
}

export type DropdownMenuSeparatorProps = RadixDropdownMenu.DropdownMenuSeparatorProps;
function Separator(props: DropdownMenuSeparatorProps) {
  const { className, ...rest } = props;
  return <RadixDropdownMenu.Separator className={cn('my-1 border-t border-grey-100', className)} {...rest} />;
}

function Label(props: RadixDropdownMenu.DropdownMenuLabelProps) {
  const { className, ...rest } = props;
  return (
    <RadixDropdownMenu.Label
      className={cn('py-1 pl-1 pr-1 text-xs font-medium leading-4 text-grey-500 [.align-icons_&]:pl-7', className)}
      {...rest}
    />
  );
}

export const DropdownMenu = {
  Root,
  Trigger,
  Content,
  Item,
  CheckboxItem,
  Separator,
  Label,
  Sub,
  SubTrigger,
  SubContent,
};

const itemStyle = cva('', {
  variants: {
    variant: {
      unstyled: '',
      plain:
        'flex cursor-pointer items-center gap-2 rounded-sm px-1 py-0.5 text-sm leading-6 text-grey-600 outline-none hover:bg-grey-50 hover:text-black focus-visible:bg-grey-50 focus-visible:text-black',
      menu: 'flex cursor-pointer items-center gap-4 rounded-sm py-3 pl-4 pr-7 text-sm leading-6 text-white outline-none hover:bg-grey-25 hover:text-white focus-visible:text-white',
    },
    disabled: {
      true: 'cursor-not-allowed text-grey-200',
      false: 'cursor-pointer',
    },
  },
  defaultVariants: {
    variant: 'plain',
    disabled: false,
  },
});

const dropdownClasses = cva([], {
  variants: {
    variant: {
      plain: 'bg-white px-2',
      menu: 'bg-black',
    },
  },
  defaultVariants: {
    variant: 'plain',
  },
});
